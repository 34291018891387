@font-face {
    font-family: Montserrat;
    src: url(../../assets/fonts/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw0aXx-p7K4KLjztg.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
    font-family: Montserrat;
    src: url(../../assets/fonts/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw9aXx-p7K4KLjztg.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
    font-family: Montserrat;
    src: url(../../assets/fonts/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw2aXx-p7K4KLjztg.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
    font-family: Montserrat;
    src: url(../../assets/fonts/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw3aXx-p7K4KLjztg.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
    font-family: Montserrat;
    src: url(../../assets/fonts/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw5aXx-p7K4KLg.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.btn-primary {
    background-color: #008558 !important;
}

.mediathequeCardImage {
    background : radial-gradient(rgba(20,20,20,0.12) 0%, #171616 100%), url(../img/Frame23.png) center / cover no-repeat, #000000;width: 100%;height: 300px;
}

.backgroundAppeldoffre {
    background: radial-gradient(rgba(36,44,55,0.67) 2%, #242c37 100%), url(../img/tender.png) center / cover no-repeat, rgba(25,135,84,0);margin-bottom: 60px;
    color: #ffb800;
    height: 30vh;
}

.border-0 .card-title {
    color: #0D6E77;
}

.contactForm input, .contactForm .btn-primary {
    height: 50px;
}

.gmap_canvas {overflow:hidden;background:none!important;height:384px;width:442px;}

.mapouter{position:relative;text-align:right;height:384px;width:442px;}

.btn-outline-primary {

    /* border-color: #2358a6 !important; */
    color: #008558 !important;
    font-size: 16px;
    /* border: 2px solid #008558 !important */
    padding: 8px 0px !important;
    border-radius: 0px !important;
    font-weight: bolder;
    border-bottom:  2px solid #008558 !important


}

.btn-outline-primary:hover {
   
    background-color:  transparent !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
   
    
    
}

.card-link {
    color: #2358a6;
    text-decoration: none;
    font-size: 17px;
    font-weight: 700
    
}

.fit-cover {
    object-fit: cover;
}
.footer-nav-title {
    font-weight: bolder;
    border-top: 0.5px solid rgba(255, 255, 255, 0.35);
    padding: 12px 0;
}
.card-newsletter {
    border-radius: 8px;
    overflow: hidden;
}
.lire-la-suite {
    font-size: 14px;
}
.info-card.category {
    font-size: 9px;
}
.gflex {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
}
.social {
    background-color: #293240;
    color: #ff0;
}
@media (min-width: 576px) {
    .gflex {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px 0;
    }
}
.gflex-btwn {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
}
nav a .nav-link {
    color: #151515 !important;
}
.page-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.intouch {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.bs-icon {
    --bs-icon-size: 0.75rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: var(--bs-icon-size);
    width: calc(var(--bs-icon-size) * 2);
    height: calc(var(--bs-icon-size) * 2);
    color: var(--bs-primary);
}
.bs-icon-xs {
    --bs-icon-size: 1rem;
    width: calc(var(--bs-icon-size) * 1.5);
    height: calc(var(--bs-icon-size) * 1.5);
}
.bs-icon-sm {
    --bs-icon-size: 1rem;
}
.bs-icon-md {
    --bs-icon-size: 1.5rem;
}
.bs-icon-lg {
    --bs-icon-size: 2rem;
}
.bs-icon-xl {
    --bs-icon-size: 2.5rem;
}
.bs-icon.bs-icon-primary {
    color: var(--bs-white);
    background: var(--bs-primary);
}
.bs-icon.bs-icon-primary-light {
    color: var(--bs-primary);
    background: rgba(var(--bs-primary-rgb), 0.2);
}
.bs-icon.bs-icon-semi-white {
    color: var(--bs-primary);
    background: rgba(255, 255, 255, 0.5);
}
.bs-icon.bs-icon-rounded {
    border-radius: 0.5rem;
}
.bs-icon.bs-icon-circle {
    border-radius: 50%;
}
.newsletter8 {
    font-family: Montserrat, sans-serif;
    color: #8d97ad;
    font-weight: 300;
}
.newsletter8 h1,
.newsletter8 h2,
.newsletter8 h3,
.newsletter8 h4,
.newsletter8 h5,
.newsletter8 h6 {
    color: #3e4555;
}
.newsletter8 .subtitle {
    color: #8d97ad;
    line-height: 24px;
}
.newsletter8 a {
    color: #263238;
}
.newsletter8 .text-themecolor,
.newsletter8 a:hover {
    color: #316ce8;
}
.newsletter8 .blog-row h5 {
    font-weight: 500;
    margin-top: 10px;
    font-size: 18px;
}
.newsletter8 .btn-danger-gradiant {
    background: #ff4d7e;
    background: -webkit-linear-gradient(legacy-direction(to right), #ff4d7e 0, #ff6a5b 100%);
    background: -webkit-gradient(linear, left top, right top, from(#ff4d7e), to(#ff6a5b));
    background: -webkit-linear-gradient(left, #ff4d7e 0, #ff6a5b 100%);
    background: -o-linear-gradient(left, #ff4d7e 0, #ff6a5b 100%);
    background: linear-gradient(to right, #ff4d7e 0, #ff6a5b 100%);
}
.newsletter8 .btn-danger-gradiant:hover {
    background: #ff6a5b;
    background: -webkit-linear-gradient(legacy-direction(to right), #ff6a5b 0, #ff4d7e 100%);
    background: -webkit-gradient(linear, left top, right top, from(#ff6a5b), to(#ff4d7e));
    background: -webkit-linear-gradient(left, #ff6a5b 0, #ff4d7e 100%);
    background: -o-linear-gradient(left, #ff6a5b 0, #ff4d7e 100%);
    background: linear-gradient(to right, #ff6a5b 0, #ff4d7e 100%);
}
.newsletter8 .btn-md {
    padding: 15px 45px;
    font-size: 16px;
}
.newsletter8 .text-danger {
    color: #ff4d7e !important;
}
.simple-slider .swiper-slide {
    height: 75vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.newspremier{
    width: 60vw !important;
}

.simple-slider .swiper-button-next,
.simple-slider .swiper-button-prev {
    width: 50px;
    margin-left: 20px;
    margin-right: 20px;
}
@media (max-width: 767px) {
    .simple-slider .swiper-button-next,
    .simple-slider .swiper-button-prev {
        display: none;
    }
    .simple-slider .swiper-slide {
        height: 360px;
    }

    .marginTop h4{
        font-size: 20px;
    }

    .marginTop h6{
        font-size: 13px;
    }
}
html {
    --bs-body-bg: #f4f5f7;
}
:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #008558;
    --bs-secondary: #008558;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
}
.perso {
    background-color: var(--bs-purple);
    color: #fff;
}
.sectionTitle {
    color: #000;
}
.indicateur {
    gap: 8px;
}
.indicateur img {
    width: 60px;
    height: 60px;
}
.card.indicator.heading-bold {
    font-weight: bolder;
}
.card.indicator.p {
    font-size: 1rem;
}
.p.special {
    line-height: 2rem;
}
.btn-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.infobox {
    height: 60px;
    background-color: rgba(237, 243, 254, 0.99);
}
@media (min-width: 300px) {
    .news-second {
        margin-bottom: 2rem;
    }
}
.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.block-img img {
    width: 600px;
    position: relative;
    top: 10%;
    left: 2%;
}
.block-img {
    position: absolute;
    bottom: 3rem;
    left: -55%;
}
.color {
    height: 650px;
    width: 500px;
    background-color: #2358a6;
}
.navigation {
    width: 109px;
    height: 44px;
    background-color: #ffffff;
    position: absolute;
    bottom: 1.1rem;
    left: -17%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.events {
    background-color: #fff;
}
@media (min-width: 768px) {
    .event-img {
        display: none;
    }
    .block-img img {
        width: 400px;
        position: relative;
        top: 10%;
        left: -8%;
    }
    .color {
        height: 450px;
        width: 300px;
        background-color: #2358a6;
    }
}
@media (min-width: 992px) {
    .envent-detail {
        display: flex;
        flex-direction: column;
        padding: 0 150px 0 0;
        flex-grow: 1;
        gap: 16px;
    }
    .event-img {
        display: flex;
        flex-direction: row-reverse;
        justify-content: end;
        position: relative;
    }
}
@media (min-width: 1200px) {
    .event-img {
        display: flex;
        flex-direction: row-reverse;
        justify-content: end;
        position: relative;
    }
}

.section-title {
    font-weight: 700;
    font-size: 49px;
    line-height: 120%;
    color: #202020;
    flex: none;
    order: 0;
    flex-grow: 0;
}

#participate {
    padding: 15px 40px;
}


.info-card-paragraphe p {
    margin-bottom: 0
}


.herosection{
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh !important;
    background-image: url("/src/assets/img/hero-img2.png");
    background-position: center;
    margin-bottom: 4rem;
}

.logo1{
    width: 150px;
    height: 150px;
    background-image: url("/src/assets/img/impot.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.logo2{
    width: 300px;
    height: 150px;
    background-image: url("/src/assets/img/logo_tresor.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.logo3{
    width: 150px;
    height: 150px;
    background-image: url("/src/assets/img/LogoDGB.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}


.link-util{
    transition: all 0.5s ease-in-out;
}
.link-util a{
    color: #242424;
    font-size: 20px;
    font-weight: bolder;
    text-decoration: none;
    
}


p, p span{
    font-size: 20px !important;
    line-height: 160% !important;
    color: #212322 !important;
}

.event-card{
    transition: all 0.5s ease-in-out;
}

.event-card img{
 object-fit: cover;
}

.event-card:hover, .link-util:hover{
    box-shadow:
    0px 3.5px 2.3px -12px rgba(0, 0, 0, 0.014),
    0px 8.4px 5.5px -12px rgba(0, 0, 0, 0.02),
    0px 15.8px 10.3px -12px rgba(0, 0, 0, 0.025),
    0px 28.1px 18.3px -12px rgba(0, 0, 0, 0.03),
    0px 52.6px 34.3px -12px rgba(0, 0, 0, 0.036),
    0px 126px 82px -12px rgba(0, 0, 0, 0.05)
  ;
}

/* Ici commence le style de la gabarit des pages src/components/moduleComponents/pageComponents/ContentPage.js
 */

.page-title{
    font-size: 2rem !important;
    font-weight: bolder !important;
    background-color: #008558;
    color: white;

    
}

.page-content-wrapper{
    margin-top: -6rem !important;
}

@media (max-width: 575.98px) { 
    .page-content-wrapper{
        margin-top: 1rem !important;
    }
 }


/* fin du style de la gabarit des pages */

/* start Style dropdown du navbar */
.dropdown-menu{
    border-radius: 0px;
    padding: 0px;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2) ;
    width: 300px;
}
.dropdown-item{
    padding: 12px 24px;

}

.dropdown-item:hover{
    background-color: #008558;
    color: white;
    font-weight: bold;
}


/* End navbar style  */

